/* eslint-disable camelcase*/
import React from 'react';
import PropTypes from 'prop-types';
import { StyledBody } from '../InputActivity/styles';
import { Button } from '../PeopleHome/styles';
import { StyledModal, StyledHeader } from '../AddInputGoals/styles';
import { CheckMark, SubmitContainer } from '../AddWellnessInitiative/styles';
import { Padding, CommonContainer } from "../LegalUpdatesPopup/style";

import { WelcomeButtonContainer } from '../Content/styles';
import { withTranslation } from 'react-i18next';

class ReactPDF extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    checked: false,
    isIE: false
  }

  componentDidMount () {
    let isIE = false || !!document.documentMode;
    this.setState({
      isIE: isIE
    });
  }

  UNSAFE_componentWillReceiveProps(){
    this.setState({
      checked: false,
      pageNumber: 1
    })
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  changePage = (number) => this.setState({pageNumber: number});

  changeCheckData = () => {
    this.setState({
      checked: !this.state.checked
    })
  }

  showCheckboxData = (checked) => (
    <CommonContainer borderTop={1}>
      <Padding>
        <div className="check">
          <CheckMark checked={checked} onClick={() => this.changeCheckData()}/>
        </div>
        <div className="name">
          {this.props.t("I am uploading my completed biometric screening form")}.
        </div>
      </Padding>
    </CommonContainer>
  )

  render() {
    const { showModal,/*pdfBase64,*/buttonText,closeModal,showCrossMarker,removePDF,reward, t } = this.props;
    const { pageNumber, numPages, checked, isIE } = this.state;
    return (
      <StyledModal show={showModal} className="pdfModal" onHide={() => buttonText == 'Close' ? closeModal() : removePDF()}>
        {!reward ? <StyledHeader uppercase={1} closeButton={showCrossMarker}>
          <div  className="pdfHeader">{ reward ?  t('Document Form'): t("Biometric Form") }</div>
        </StyledHeader> :
          <div className={'reward-modal-header'}>
            <i onClick = {() => closeModal()} className={'close-icon fa fa-close'} />
          </div>}

        <StyledBody isIE={isIE}>
          <div className="pdf">
            {/* <Document 
              loading="Please wait"
              file={pdfBase64}
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document> */}
          </div>
          <WelcomeButtonContainer small>
            { numPages !== 1 ? <p className="page">
              <span 
                className={`glyphicon glyphicon-chevron-left ${pageNumber === 1 ? 'iconDisable' : 'iconBlue'}`} 
                onClick={() => pageNumber!== 1 && this.changePage(pageNumber-1)}
              />
              {t("Page")} {t(pageNumber)} {t("of")} {t(numPages)}
              <span 
                className={`glyphicon glyphicon-chevron-right ${pageNumber == numPages ? 'iconDisable' : 'iconBlue'}`}
                onClick={() => pageNumber!= numPages && this.changePage(pageNumber+1)}
              />
            </p> : null }
          </WelcomeButtonContainer>
        </StyledBody>
        { buttonText !== 'Close' ?
          <div>
            {reward ? null : this.showCheckboxData(checked)}
            <SubmitContainer>
              <Button className="biometric-button" onClick = {() => closeModal()} disabled={!checked}>
                {t(buttonText)}
              </Button>
            </SubmitContainer>
          </div> : null
        }
      </StyledModal>
    )
  }
} 

ReactPDF.propTypes =  {
  showModal: PropTypes.bool,
  pdfBase64: PropTypes.string,
  closeModal: PropTypes.func,
  buttonText: PropTypes.string,
  showCrossMarker: PropTypes.bool,
  removePDF: PropTypes.func,
  reward: PropTypes.number,
  t: PropTypes.func
};

export default (withTranslation()(ReactPDF));

