import {RecipeCard} from '../styles';
import {SplitEngagementSection} from "../../EngagementSection/styles";
import styled from "styled-components";
const RecipeCategoryCard =  styled(RecipeCard)`
  width: calc((100% - 20px) / 2);
  @media (max-width: 700px){
    width: calc((100% - 40px) / 2);
    margin: 10px;
  }
  @media (max-width: 500px){
    width: 100%;
  }
`;
const CategoryRecipeContainer = styled(SplitEngagementSection)`
  width: 67.1%;
  @media (max-width: 1146px) {
    width: 100%;
  }
`;
const RecipesContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  flex-wrap: wrap;
`;
export {RecipeCategoryCard, CategoryRecipeContainer, RecipesContainer};